<template>
  <section id="politicaDePrivacidad">
    <div id="bloqueSuperior">
      <div id="bloqueSuperior__texto">
        <h1>PRIVACIDAD</h1>
        <p>
          Aquí encontrarás cómo gestionamos el tratamiento de datos personales.
        </p>
      </div>
    </div>
    <div id="bloqueInferior">
      <h2>Declaraci&oacute;n sobre protecci&oacute;n de datos</h2>
      <p>
        Este sitio web (en adelante, el "sitio web") ha sido desarrollada por
        Bayer Hispania, S.L.U. (en adelante"nosotros" o "nuestro"). Para obtener
        m&aacute;s informaci&oacute;n sobre el proveedor del sitio web, consulte
        nuestro
        <a
          title="pie de imprenta"
          href="https://www.bayer.com/es/es/espana-pie-de-imprenta"
          target="_blank"
          >pie de imprenta</a
        >.
      </p>
      <h2>Manejo de los datos personales</h2>
      <p>
        A continuaci&oacute;n, deseamos proporcionarle informaci&oacute;n sobre
        c&oacute;mo tratamos sus datos personales cuando utiliza nuestro sitio
        web. A menos que se indique lo contrario en los siguientes
        cap&iacute;tulos, la base jur&iacute;dica para el tratamiento de sus
        datos personales resulta del hecho de que dicho tratamiento es necesario
        para poner adisposici&oacute;n las funcionalidades del sitio web
        solicitadas por usted (Art. 6(1)(b), del Reglamento General
        deProtecci&oacute;n de Datos).
      </p>
      <h3>Uso de nuestro sitio web</h3>
      <h4>Acceder a nuestro sitio web</h4>
      <p>
        Cuando se ingresa a nuestro sitio web, su navegador transferir&aacute;
        ciertos datos a nuestro servidor web. Esto sehace por razones
        t&eacute;cnicas y se requiere para poner a su disposici&oacute;n la
        informaci&oacute;n solicitada. Para facilitar suacceso la p&aacute;gina,
        se recopilan, almacenan y utilizan brevemente los siguientes datos:
      </p>
      <ul>
        <li>
          <p>Direcci&oacute;n IP</p>
        </li>
        <li>
          <p>Fecha y hora de acceso</p>
        </li>
        <li>
          <p>
            Diferencia de zona horaria a la hora del meridiano de Greenwich
            (GMT)
          </p>
        </li>
        <li>
          <p>Contenido de la solicitud (sitio espec&iacute;fico)</p>
        </li>
        <li>
          <p>Estado del acceso/c&oacute;digo de estado HTTP</p>
        </li>
        <li>
          <p>Volumen transferido de datos</p>
        </li>
        <li>
          <p>Sitio web que solicita acceso</p>
        </li>
        <li>
          <p>
            Navegador, configuraci&oacute;n de idioma, versi&oacute;n del
            Sistema operativo del navegador y resoluci&oacute;n de pantalla
          </p>
        </li>
      </ul>
      <p>
        Al mismo tiempo, para proteger nuestros intereses leg&iacute;timos,
        almacenaremos dichos datos durante un per&iacute;odode tiempo limitado a
        fin de poder iniciar un seguimiento de los datos personales en caso de
        que haya un accesoo intento de acceso no autorizado a nuestros
        servidores (Art. 6(1)(f), del Reglamento General de Protecci&oacute;n
        deDatos).
      </p>
      <h3>Configuraci&oacute;n de cookies</h3>
      <h4>&iquest;Qu&eacute; son las cookies?</h4>
      <p>
        Este sitio web utiliza las llamadas "cookies". Las cookies son
        peque&ntilde;os archivos de texto que se almacenan enla memoria de su
        terminal a trav&eacute;s de su navegador. Almacenan cierta
        informaci&oacute;n (por ejemplo, su idiomapreferido o la
        configuraci&oacute;n del sitio) que su navegador puede (dependiendo de
        la vida &uacute;til de la cookie)retransmitirnos en su pr&oacute;xima
        visita a nuestro sitio web.
      </p>
      <h4>&iquest;Qu&eacute; cookies utilizamos?</h4>
      <p>
        Diferenciamos entre dos categor&iacute;as de cookies: (1)
        <b>cookies funcionales</b>, sin las cuales se reducir&iacute;a la
        funcionalidad de nuestro sitio web, y (2)
        <b>cookies opcionales</b> utilizadas para, por ejemplo, an&aacute;lisis
        del sitio weby fines de marketing. Las siguientes tablas contienen una
        descripci&oacute;n detallada de las cookies opcionales que utilizamos:
      </p>
      <h4>Cookies opcionales</h4>
      <table>
        <thead>
          <tr>
            <th width="25%">Nombre de la cookie</th>
            <th width="25%">Duraci&oacute;n predeterminada</th>
            <th width="50%">Descripci&oacute;n</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code dir="ltr" translate="no">_<wbr />ga</code>
            </td>
            <td>2 a&ntilde;os</td>
            <td>Se usa para distinguir a los usuarios.</td>
          </tr>
          <tr>
            <td>
              <code dir="ltr" translate="no">_<wbr />gid</code>
            </td>
            <td>24&nbsp;horas</td>
            <td>Se usa para distinguir a los usuarios.</td>
          </tr>
          <tr>
            <td>
              <code dir="ltr" translate="no"
                >_<wbr />ga_<wbr />&lt;container-id&gt;</code
              >
            </td>
            <td>2 a&ntilde;os</td>
            <td>Se usa para mantener el estado de la sesi&oacute;n.</td>
          </tr>
          <tr>
            <td>
              <code dir="ltr" translate="no"
                >_<wbr />gac_<wbr />gb_<wbr />&lt;container-id&gt;</code
              >
            </td>
            <td>90 d&iacute;as</td>
            <td>
              Incluye informaci&oacute;n relacionada con la campa&ntilde;a. Si
              has vinculado tus cuentas de Google Analytics y Google Ads, las
              etiquetas de conversi&oacute;n en sitio web de Google Ads
              leer&aacute;n esta cookie, a menos que la inhabilites.&nbsp;<a
                href="https://support.google.com/google-ads/answer/7521212?hl=es"
                target="_blank"
                >M&aacute;s informaci&oacute;n</a
              >
            </td>
          </tr>
        </tbody>
      </table>
      <h4>Sujeto a su consentimiento</h4>
      <p>
        S&oacute;lo utilizamos cookies opcionales si hemos obtenido su
        consentimiento con anterioridad (Art. 6(1)(a), delReglamento General de
        Protecci&oacute;n de Datos). Tras su primer acceso a nuestra
        p&aacute;gina, aparecer&aacute; un banner quele pedir&aacute; que nos
        d&eacute; su consentimiento para la configuraci&oacute;n de cookies
        opcionales. Si otorga su consentimiento, colocaremos una cookie en su
        ordenador y el banner no aparecer&aacute; de nuevo mientras la cookie
        est&eacute; activa. Despu&eacute;s de la vencimiento de la
        duraci&oacute;n de la cookie, o si la elimina de manera activa, el
        bannervolver&aacute; a aparecer en su pr&oacute;xima visita a nuestra
        p&aacute;gina y le pedir&aacute; nuevamente su consentimiento.
      </p>
      <h4>&iquest;C&oacute;mo evitar la configuraci&oacute;n de cookies?</h4>
      <p>
        Por supuesto, puede usar nuestra p&aacute;gina sin que se establezcan
        cookies. En su navegador, puede configurar odesactivar completamente el
        uso de cookies en cualquier momento. Sin embargo, esto puede conducir a
        unarestricci&oacute;n de las funciones o tener efectos negativos sobre
        la facilidad de uso de nuestra p&aacute;gina. En cualquier momento puede
        objetar la configuraci&oacute;n de cookies opcionales, utilizando la
        opci&oacute;n de objeci&oacute;nrespectiva indicado en la tabla de
        arriba.
      </p>
      <h3>
        An&aacute;lisis de sitios web y publicidad comportamental en
        l&iacute;nea
      </h3>
      <h4>An&aacute;lisis de sitios web con Google</h4>
      <p>
        En nuestro sitio web utilizamos un servicio de an&aacute;lisis web de
        Google Inc., 1600 Amphitheatre Parkway,Mountain View, CA 94043, Estados
        Unidos ("Google").
      </p>
      <p>
        Google analizar&aacute; su uso de nuestro sitio web en nuestro nombre.
        Para ello, utilizamos las cookies descritas conm&aacute;s detalle en la
        tabla anterior. La informaci&oacute;n recopilada por Google en
        relaci&oacute;n con su uso de nuestro sitioweb (por ejemplo, la URL de
        referencia, nuestras p&aacute;ginas web visitadas por usted, su tipo de
        navegador, suconfiguraci&oacute;n de idioma, su sistema operativo, su
        resoluci&oacute;n de pantalla) se transmitir&aacute; a un servidor de
        Google en los EE. UU., donde se almacenar&aacute; y analizar&aacute;.
        Los resultados respectivos se pondr&aacute;n a nuestra
        disposici&oacute;nde forma an&oacute;nima. Sus datos de uso no se
        conectar&aacute;n a su direcci&oacute;n IP completa durante este
        proceso. Hemosactivado en nuestro sitio web la funci&oacute;n de
        anonimizaci&oacute;n de IP ofrecida por Google, que eliminar&aacute; los
        &uacute;ltimos8 bits (tipo IPv4) o los &uacute;ltimos 80 bits (tipo
        IPv6) de su direcci&oacute;n IP despu&eacute;s de cada transferencia de
        datos a Google.
      </p>
      <p>
        Al celebrar acuerdos espec&iacute;ficos con Google, garantizamos un
        nivel adecuado de protecci&oacute;n de datos enrelaci&oacute;n con el
        tratamiento de datos personales por parte de Google en los EE. UU.
      </p>
      <p>
        Puede retirar su consentimiento para el uso del an&aacute;lisis web en
        cualquier momento, ya sea descargando e instalando el
        <a
          title="Google Browser Plugin"
          href="https://tools.google.com/dlpage/gaoptout?hl=en-GB"
          target="_blank"
          >Google Browser Plugin</a
        >
        o administrando sus consentimientos en la tabla anterior, en cuyo caso
        secolocar&aacute; una cookie de exclusi&oacute;n (opt-out). Ambas
        opciones evitar&aacute;n la aplicaci&oacute;n de an&aacute;lisis web
        solomientras utilice el navegador en el que instal&oacute; el
        complemento y no elimine la cookie de exclusi&oacute;n (opt-out).
      </p>
      <p>
        Encontrar&aacute; m&aacute;s informaci&oacute;n sobre Google Analytics
        en las
        <a
          title="Condiciones de uso de Google Analytics"
          href="https://marketingplatform.google.com/about/analytics/terms/us/"
          target="_blank"
          >Condiciones de uso de Google Analytics</a
        >, en las
        <a
          title="Directivas de privacidad y protecci&oacute;n de datos de Google Analytics"
          href="https://support.google.com/analytics/answer/6004245?hl=en-GB"
          target="_blank"
          >Directivas de privacidad y protecci&oacute;n de datos de Google
          Analytics</a
        >
        y en
        <a
          title="la Pol&iacute;tica de privacidad de Google."
          href="https://policies.google.com/privacy?hl=en"
          >la Pol&iacute;tica de privacidad de Google.</a
        >
      </p>
      <h3>
        Informaci&oacute;n sobre acontecimientos adversos y reclamaciones de
        calidad
      </h3>
      <p>
        Este sitio web no est&aacute; destinado ni dise&ntilde;ado para
        comunicaciones relacionadas a acontecimientos adversos,falta de efecto
        terap&eacute;utico, errores de medicaci&oacute;n, productos del mercado
        negro/medicamentos falsificados, usoincorrecto o fuera de
        indicaci&oacute;n aprobada, reclamaciones de calidad u otros problemas
        relacionados con laseguridad o la calidad de los productos de Bayer. Si
        desea informar sobre cualquier acontecimiento adverso opresentar una
        reclamaci&oacute;n de calidad, comun&iacute;quese con su profesional de
        la salud (por ejemplo, un m&eacute;dico ofarmac&eacute;utico), su
        autoridad sanitaria local o utilice nuestra
        <a
          title="p&aacute;gina"
          href="https://www.bayer.com/es/es/espana-reaccion-adversa"
          target="_blank"
          >p&aacute;gina</a
        >
        para informar sobre los acontecimientos adversos no deseados.
      </p>
      <p>
        No obstante, si nos informa sobre acontecimientos adversos no deseados u
        otros problemas relacionados con laseguridad o la calidad de los
        productos de Bayer, estaremos legalmente obligados a procesar su
        comunicaci&oacute;n yes posible que tengamos que comunicarnos con usted
        para fines de aclaraci&oacute;n. Posteriormente, es posible quetengamos
        que notificar a las autoridades sanitarias competentes sobre los
        problemas informados por usted. Eneste contexto, su informaci&oacute;n
        se enviar&aacute; de forma seudonimizada, es decir, no se
        transmitir&aacute; ningunainformaci&oacute;n por la que pueda ser
        identificado directamente. Tambi&eacute;n es posible que tengamos que
        enviar estasnotificaciones seudonimizadas a compa&ntilde;&iacute;as
        pertenecientes a nuestro grupo y a nuestros socios de
        cooperaci&oacute;n,en la medida en que est&eacute;n igualmente obligados
        a notificar a sus respectivas autoridades sanitarias competentes.
      </p>
      <p>
        Para m&aacute;s informaci&oacute;n sobre la privacidad de los datos y la
        notificaci&oacute;n de los efectos secundarios, consulte la
        <a
          title="declaraci&oacute;n de privacidad para la Farmacovigilancia."
          href="https://www.bayer.com/en/privacy-statement-pv"
          >declaraci&oacute;n de privacidad para la Farmacovigilancia.</a
        >
      </p>
      <h3>Transferencia de datos personales</h3>
      <h4>Tratamiento por encargo</h4>
      <p>
        Para el tratamiento de sus datos personales, utilizaremos hasta cierto
        punto proveedores de servicios especializados que tratan sus datos en
        nuestro nombre (por ejemplo, para soporte de TI o servicios en la nube).
      </p>
      <p>
        Dichos proveedores de servicios son cuidadosamente seleccionados y
        supervisados regularmente por nosotros.Seg&uacute;n los respectivos
        acuerdos de tratamiento de datos, solo tratar&aacute;n datos personales
        de acuerdo con nuestrasinstrucciones.
      </p>
      <h4>Afiliados</h4>
      <p>
        Podemos compartir sus datos personales con nuestros afiliados del Grupo
        Bayer, cuando sea necesario para losfines descritos anteriormente.
      </p>
      <h4>Autoridades e instituciones estatales</h4>
      <p>
        Podemos compartir sus datos personales con los organismos encargados de
        hacer cumplir la ley &ldquo;u otras autoridades e instituciones
        estatales si as&iacute; lo exige la ley o es necesario para los fines
        descritos anteriormente.
      </p>
      <h4>Abogados externos</h4>
      <p>
        Para respaldar decisiones legales y para perseguir o defenderse de
        reclamaciones legales, podemos compartirsus datos personales con
        abogados externos.
      </p>
      <h4>Posibles compradores en el contexto de Fusiones y Adquisiciones</h4>
      <p>
        Podemos compartir sus datos personales con un posible comprador en caso
        de una adquisici&oacute;n, fusi&oacute;n ocualquier otro tipo de
        transici&oacute;n corporativa o de activos que implique un cambio de
        propiedad o control quenos afecte a nosotros o a nuestros servicios.
      </p>
      <h3>Tratamiento de datos personales fuera de la UE/del EEE</h3>
      <p>
        Sus datos personales tambi&eacute;n se procesar&aacute;n en parte en
        pa&iacute;ses fuera de la Uni&oacute;n Europea ("UE") o del
        EspacioEcon&oacute;mico Europeo ("EEE"), que pueden tener un nivel de
        protecci&oacute;n de datos inferior al de los pa&iacute;ses europeos. En
        tales casos, nos aseguraremos de que se proporcione un nivel de
        protecci&oacute;n suficiente para susdatos personales, por ejemplo,
        mediante la celebraci&oacute;n de acuerdos espec&iacute;ficos con
        nuestros socios contractuales (copia disponible a petici&oacute;n), o
        solicitaremos su consentimiento expl&iacute;cito para dicho tratamiento.
      </p>
      <h2>Informaci&oacute;n sobre sus derechos</h2>
      <p>
        En general, se dispone de los siguientes derechos de acuerdo con las
        leyes de protecci&oacute;n de datos aplicables:
      </p>
      <ul>
        <li>
          <p>
            Derecho de informaci&oacute;n sobre sus datos personales almacenados
            por nosotros;
          </p>
        </li>
        <li>
          <p>
            Derecho a solicitar la rectificaci&oacute;n, supresi&oacute;n o
            limitaci&oacute;n del tratamiento de sus datos personales;
          </p>
        </li>
        <li>
          <p>
            Derecho a oponerse a un tratamiento derivado de nuestro propio
            inter&eacute;s leg&iacute;timo, inter&eacute;s p&uacute;blico
            oelaboraci&oacute;n de perfiles, a menos que podamos demostrar que
            existen razones imperiosas y justificadasque superan sus intereses,
            derechos y libertades, o que dicho tratamiento se realiza para
            lareivindicaci&oacute;n, el ejercicio o la defensa de reclamaciones
            legales;
          </p>
        </li>
        <li>
          <p>
            Derecho a la portabilidad de los datos;&bull; Derecho a presentar
            una reclamaci&oacute;n ante una autoridad de protecci&oacute;n de
            datos;
          </p>
        </li>
        <li>
          <p>
            Usted puede, en cualquier momento y con efecto futuro, retirar su
            consentimiento para el tratamiento desus datos personales.
          </p>
        </li>
      </ul>
      <p>
        Para obtener m&aacute;s informaci&oacute;n, consulte los
        cap&iacute;tulos anteriores que describen el tratamiento de datos
        personales sobre la base de su consentimiento. Si desea ejercer sus
        derechos, dirija su solicitud a trav&eacute;s del
        <a
          title="formulario de contacto"
          href="https://www.bayer.com/en/contacting-data-privacy"
          target="_blank"
          >formulario de contacto</a
        >
        (https://www.bayer.com/en/contacting-data-privacy) o a nuestra oficina
        de protecci&oacute;n dedatos de la empresa que se indica a
        continuaci&oacute;n.
      </p>
      <h2>Contacto</h2>
      <p>
        Para cualquier pregunta que pueda tener con respecto a la privacidad de
        los datos, env&iacute;e su solicitud a nuestro
        <a
          title="formulario de contacto"
          href="https://www.bayer.com/en/contacting-data-privacy"
          target="_blank"
          >formulario de contacto</a
        >
        o comun&iacute;quese con el oficial de protecci&oacute;n de datos de
        nuestra empresa en la siguiente direcci&oacute;n:
      </p>
      <p><b>Data Privacy Officer</b></p>
      <p>BAYER HISPANIA, S.L.</p>
      <p>Avda. Baix Llobregat, 3 - 5</p>
      <p>08970 - Sant Joan Desp&iacute; (Barcelona)</p>
      <p>Espa&ntilde;a</p>
      <p>CIF: B-08193013</p>
      <h2>Modificaciones a la Pol&iacute;tica de Privacidad</h2>
      <p>
        Es posible que actualicemos nuestra Pol&iacute;tica de Privacidad de vez
        en cuando. Las actualizaciones de nuestraPol&iacute;tica de Privacidad
        se publicar&aacute;n en nuestra sitio web. Todas las modificaciones
        entran en vigencia una vez publicadas en nuestra sitio web. Por lo tanto
        recomendamos que visite regularmente la sitio web paramantenerse
        informado sobre posibles actualizaciones.
      </p>
      <p>&Uacute;ltima actualizaci&oacute;n: 07-21-2022</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "PoliticaDePrivacidadPrincipal",
};
</script>